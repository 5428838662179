import numberToCurrency from '@/utilities/numberToCurrency';

export const OddsGameAmount = (props: {
  currency: string;
  gameAmount: number | null;
}) => {
  const getCurrency = () => {
    if (props.currency === '€' || props.currency === 'EUR') {
      return '€';
    } else {
      return 'DM';
    }
  };
  return (
    <div className="GameAmount">
      Spieleinsatz:{' '}
      {props.gameAmount
        ? numberToCurrency(props.gameAmount, getCurrency())
        : 'wird ermittelt'}
    </div>
  );
};
