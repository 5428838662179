import React from 'react';

interface IOnLineState {
  isOnline: boolean;
}

class OnLine extends React.Component<
  { children?: React.ReactNode },
  IOnLineState
> {
  constructor(props) {
    super(props);
    this.state = { isOnline: true };
  }

  public componentDidMount() {
    this.updateState();
    ['online', 'offline'].forEach((eventType) =>
      window.addEventListener(eventType, this.updateState)
    );
  }

  public componentWillUnmount() {
    ['online', 'offline'].forEach((eventType) =>
      window.removeEventListener(eventType, this.updateState)
    );
  }

  public render() {
    return (this.state.isOnline && this.props.children) || null;
  }

  updateState = () => {
    if (navigator) {
      this.setState(() => ({
        isOnline: navigator.onLine,
      }));
    }
  };
}

export default OnLine;
