import classnames from 'classnames';
import React from 'react';

export const OddsTableRow = (props: {
  columns: string[];
  className?: string;
  head?: boolean;
}) => {
  const selectorType: string = props.head ? 'th' : 'td';
  const Cell = (props) => React.createElement(selectorType, props);

  return (
    <tr
      className={classnames(props.className, 'OddsTableRow', {
        'OddsTableRow--head': props.head,
      })}
    >
      {props.columns.map((column, idx) => (
        <Cell
          key={idx}
          dangerouslySetInnerHTML={{
            __html: column || '-',
          }}
        />
      ))}
    </tr>
  );
};
