import React from 'react';

export interface OfflinePlaceholderProps {
  box?: boolean;
  className?: string;
}

const OfflinePlaceholder: React.FC<
  React.PropsWithChildren<OfflinePlaceholderProps>
> = ({ box, className }) => {
  const Placeholder = () => (
    <img
      className={className || 'OfflinePlaceholder__image'}
      src={'/assets/images/offline-video.jpg'}
      alt="Offline"
    />
  );

  const PlaceholderBox = () => (
    <div className="OfflinePlaceholder--box">
      <div className="OfflinePlaceholder__container">
        <Placeholder />
      </div>
    </div>
  );

  return box ? <PlaceholderBox /> : <Placeholder />;
};

export default OfflinePlaceholder;
