import Button from '@/components/Button';
import { ButtonVariant } from '@/components/ButtonVariant';
import { Icon, IconVariant } from '@/components/Icon';
import Video from '@/components/Video';
import { GameConfig } from '@/config/game';
import { WinningNumbersData } from '@/config/odds/winningNumbers';
import { videoConfig } from '@/config/video';
import { getGameByShortname } from '@/helpers/getGameByShortname';
import { DateFormat, formatDate } from '@/utilities/dateUtils';
import React from 'react';

export enum DrawVideoVariant {
  Lotto = 'lotto',
  Eurojackpot = 'eurojackpot',
  Keno = 'keno',
}

export interface DrawVideoProps {
  variant: DrawVideoVariant;
  winningNumbersData?: WinningNumbersData;
  date?: string | number;
}

interface DrawVideoState {
  variant: DrawVideoVariant;
  is360: boolean;
  youTubeData?: YouTubeData;
}

interface YouTubeData {
  [game: string]: {
    [date: string]: string;
  };
}

const findNewestDate = (dateStrings: string[]): string => {
  try {
    const dates = dateStrings.map((date) => {
      const [dd, mm, yyyy] = date.split('.');
      return new Date(`${yyyy}/${mm}/${dd}`).getTime();
    });
    const now = new Date().getTime();
    const pastDates = dates.filter((date) => date < now);
    const newestDate = Math.max(...pastDates);
    return Intl.DateTimeFormat('de-DE', { dateStyle: 'medium' }).format(
      newestDate
    );
  } catch (e) {
    return Intl.DateTimeFormat('de-DE', { dateStyle: 'medium' }).format(
      new Date()
    );
  }
};

class DrawVideo extends React.Component<DrawVideoProps, DrawVideoState> {
  constructor(props) {
    super(props);
    this.state = {
      variant: this.props.variant,
      is360: false,
    };
  }

  async componentDidMount() {
    const response = await fetch(
      `/api/data/youtube?game=${this.state.variant}`
    );
    if (response.ok) {
      this.setState({ youTubeData: await response.json() });
    }
  }

  private findVideo = (
    variant: DrawVideoVariant = this.state.variant
  ): string | undefined => {
    const { youTubeData } = this.state;
    if (youTubeData) {
      const videos = youTubeData[variant] || {};
      return videos[this.date];
    }
    return undefined;
  };

  private findVideoId = (
    variant: DrawVideoVariant = this.state.variant
  ): string | undefined => {
    return this.findVideo(variant);
  };

  private get availableDates(): string[] {
    const { youTubeData } = this.state;
    const { variant } = this.state;
    if (youTubeData) {
      return Object.keys(youTubeData[variant] || {});
    }
    return [];
  }

  private get has360(): boolean {
    const variant360 = (this.props.variant + '360') as DrawVideoVariant;
    return this.findVideoId(variant360) !== undefined;
  }

  private get date(): string {
    if (this.props.date) {
      return formatDate(this.props.date, DateFormat.Default);
    } else if (this.props.winningNumbersData && this.config) {
      return this.winningNumbersDate;
    }
    return this.fallbackDate;
  }

  private get winningNumbersDate(): string {
    if (this.props.winningNumbersData && this.config) {
      const id = this.config.id;
      const data = this.props.winningNumbersData[id.toString()];
      if (data) {
        const item = Array.isArray(data)
          ? data.sort((a, b) => {
              return !!a && !!b ? (b.drawDate || 0) - (a.drawDate || 0) : 0;
            })[0]
          : data;
        const drawDate = item.drawDate;
        return drawDate
          ? formatDate(drawDate, DateFormat.Default)
          : this.fallbackDate;
      }
      return this.fallbackDate;
    }
    return this.fallbackDate;
  }

  private get fallbackDate(): string {
    return findNewestDate(this.availableDates);
  }

  //private get thumbnailVersion(): string | undefined {
  //  const video = this.findVideo(this.state.variant);
  //  return video ? 'sddefault' : 'default';
  //}

  private get url(): string | null {
    const videoId = this.findVideoId();
    return videoId ? videoConfig.baseUrl + videoId : null;
  }

  private get config(): GameConfig | undefined {
    return getGameByShortname(this.state.variant);
  }

  private get titleStart(): string {
    const variant = this.props.variant;
    switch (variant) {
      case DrawVideoVariant.Lotto: {
        return 'Die Ziehung der Lottozahlen';
      }
      case DrawVideoVariant.Eurojackpot: {
        return 'Die Eurojackpot Ziehung';
      }
      case DrawVideoVariant.Keno: {
        return 'Die Ziehung der KENO Zahlen';
      }
      default: {
        return 'Die Ziehung';
      }
    }
  }

  private get title(): string {
    return `${this.titleStart} vom ${this.date}`;
  }

  private get buttonLabel(): string {
    if (this.state.is360) {
      return 'Normales Video ansehen';
    } else {
      return '360° Video ansehen';
    }
  }

  private getSwappedVariant = (variant: DrawVideoVariant): DrawVideoVariant => {
    if (variant.indexOf('360') < 0) {
      return (variant + '360') as DrawVideoVariant;
    } else {
      return variant.replace('360', '') as DrawVideoVariant;
    }
  };

  private handleOnClick = (): void => {
    this.setState((oldState) => {
      return {
        variant: this.getSwappedVariant(oldState.variant),
        is360: !oldState.is360,
      };
    });
  };

  componentDidUpdate(prevProps: DrawVideoProps) {
    if (prevProps.variant !== this.props.variant) {
      this.setState({
        variant: this.props.variant,
        is360: false,
      });
    }
  }

  render(): JSX.Element | null {
    const url = this.url;
    if (url) {
      return this.has360 ? (
        <div className="DrawVideo Video Video--boxed">
          <div className="DrawVideo__video">
            <Video
              url={url}
              is360={this.state.is360}
              variant={this.state.variant}
            />
          </div>
          <div className="Video__description DrawVideo__description">
            <div className="DrawVideo__title">
              <span>{this.title}</span>
            </div>
            <Button
              variant={ButtonVariant.Primary}
              onClick={this.handleOnClick}
            >
              <span>{this.buttonLabel}</span>
              <Icon variant={IconVariant.ArrowRight} />
            </Button>
          </div>
        </div>
      ) : (
        <Video
          url={url}
          is360={this.state.is360}
          title={this.title}
          boxed={true}
          variant={this.state.variant}
        />
      );
    } else if (this.winningNumbersDate !== this.fallbackDate) {
      return (
        <div className="Video Video--boxed">
          <span className="Video__notice">
            Das aktuelle Ziehungsvideo steht Ihnen in Kürze zur Verfügung
          </span>
        </div>
      );
    }
    return null;
  }
}

export default DrawVideo;
