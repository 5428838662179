
export interface YoutubePlaceholderProps {
  videoid?: string;
  is360: boolean;
  variant: string;
  thumbnailversion?: string;
  alt?: string;
  onClick?: any;
}

export default function YoutubePlaceholder(props: YoutubePlaceholderProps) {
  const placeholderProps = props;
  const className = 'Video__thumbnail';

  function placeholder(): string {
    return placeholderProps.videoid !== ''
      ? `/img.youtube.com/vi/${placeholderProps.videoid}/${placeholderProps.thumbnailversion}.jpg`
      : `/assets/images/youtube/fallback${
        placeholderProps.variant ? `-${placeholderProps.variant}` : ``
      }.webp`;
  }

  return <img
      className={className}
      src={placeholder()}
      loading="lazy"
      alt=""
      width={800}
      height={400}
    />
}
