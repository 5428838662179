import Button from '@/components/Button';
import { Icon, IconVariant } from '@/components/Icon';
import React from 'react';

interface OddsTableKenoInputProps {
  kenoType: string;
  kenoStake: string;
  handleOnChangeKenoTypeInput: Function;
  handleOnChangeKenoStakeInput: Function;
}

const OddsTableKenoInput: React.FC<
  React.PropsWithChildren<OddsTableKenoInputProps>
> = (props) => {
  const kenoTypes = [
    {
      value: '10',
      label: 'Keno-Typ 10',
    },
    {
      value: '9',
      label: 'Keno-Typ 9',
    },
    {
      value: '8',
      label: 'Keno-Typ 8',
    },
    {
      value: '7',
      label: 'Keno-Typ 7',
    },
    {
      value: '6',
      label: 'Keno-Typ 6',
    },
    {
      value: '5',
      label: 'Keno-Typ 5',
    },
    {
      value: '4',
      label: 'Keno-Typ 4',
    },
    {
      value: '3',
      label: 'Keno-Typ 3',
    },
    {
      value: '2',
      label: 'Keno-Typ 2',
    },
  ];
  const kenoStakes = [
    {
      value: '10',
      label: 'Einsatz 10 €',
    },
    {
      value: '5',
      label: 'Einsatz 5 €',
    },
    {
      value: '2',
      label: 'Einsatz 2 €',
    },
    {
      value: '1',
      label: 'Einsatz 1 €',
    },
  ];
  return (
    <div className="OddsTableKenoInput">
      <Button
        variant={Button.Variant.Primary}
        iconPosition={Button.IconPosition.Right}
        className="OddsDateInput__inputButton"
        tag={'div'}
      >
        <label htmlFor="kenoTypeSelect">
          {
            kenoTypes.filter(
              (type) => type.value === props.kenoType.toString()
            )[0].label
          }
        </label>
        <select
          name="kenoTypeSelect"
          id="kenoTypeSelect"
          value={props.kenoType}
          onChange={($event) => props.handleOnChangeKenoTypeInput($event)}
        >
          {kenoTypes.map((type, idx: number) => (
            <option value={type.value} key={idx}>
              {type.label}
            </option>
          ))}
        </select>

        <Icon variant={IconVariant.ChevronDown} />
      </Button>
      <Button
        variant={Button.Variant.Primary}
        iconPosition={Button.IconPosition.Right}
        className="OddsDateInput__inputButton"
        tag={'div'}
      >
        <label htmlFor="kenoStakeSelect">
          {
            kenoStakes.filter((stake) => stake.value === props.kenoStake)[0]
              .label
          }
        </label>
        <select
          name="kenoStakeSelect"
          id="kenoStakeSelect"
          value={props.kenoStake}
          onChange={($event) => props.handleOnChangeKenoStakeInput($event)}
        >
          {kenoStakes.map((stake, idx: number) => (
            <option value={stake.value} key={idx}>
              {stake.label}
            </option>
          ))}
        </select>
        <Icon variant={IconVariant.ChevronDown} />
      </Button>
    </div>
  );
};

export default OddsTableKenoInput;
