import Logo from '@/components/Logo';
import { LogoVariant } from '@/components/LogoVariant';
import { OddsGameAmount } from '@/components/OddsGameAmount';
import OddsTableKenoInput from '@/components/OddsTableKenoInput';
import { OddsTableRow } from '@/components/OddsTableRow';
import classnames from 'classnames';

export enum OddsTableGameType {
  Lotto6aus49 = 'lotto6aus49',
  Spiel77 = 'spiel77',
  Super6 = 'super6',
  Keno = 'keno',
  Plus5 = 'plus5',
  Eurojackpot = 'eurojackpot',
  Totoaw = 'totoaw',
  Totoew = 'totoew',
}

export const OddsTable = ({
  gameType,
  rows,
  logo,
  gameAmount = null,
  currency = '€',
  handleOnChangeKenoTypeInput,
  handleOnChangeKenoStakeInput,
  kenoType = '10',
  kenoStake = '10',
  label,
  hideWinningAmounts,
}: {
  gameType: OddsTableGameType;
  gameAmount?: number | null;
  currency: string;
  logo: LogoVariant;
  rows: any;
  handleOnChangeKenoTypeInput?: Function;
  handleOnChangeKenoStakeInput?: Function;
  kenoType?: string;
  kenoStake?: string;
  label?: string;
  hideWinningAmounts?: boolean;
}) => {
  const HeaderClassNames = classnames('OddsTableContainer__header', {
    [`OddsTableContainer__header--${gameType}`]: !!gameType,
  });

  const rowsHead = rows.filter((row, index) => index === 0);
  const rowsBody = rows.filter((row, index) => index !== 0);

  let oddsTableRowClass =
    gameType === OddsTableGameType.Keno || gameType === OddsTableGameType.Plus5
      ? 'OddsTableRow--merge-first-cells'
      : '';
  oddsTableRowClass +=
    gameType === OddsTableGameType.Keno
      ? ' OddsTableRow--hide-winning-class'
      : '';
  oddsTableRowClass += hideWinningAmounts
    ? ' OddsTableRow--hide-winning-amount'
    : '';

  return (
    <div className="OddsTableContainer">
      <div className={HeaderClassNames}>
        <Logo className="OddsTableContainer__logo" variant={logo} />
        {!hideWinningAmounts && (
          <span>
            Gewinnquoten
            {label && ` - ${label}`}
          </span>
        )}
      </div>
      <div className="OddsTableContainer__content">
        {!hideWinningAmounts && (
          <OddsGameAmount gameAmount={gameAmount} currency={currency} />
        )}
        {gameType === OddsTableGameType.Keno && (
          <>
            {handleOnChangeKenoTypeInput && handleOnChangeKenoStakeInput && (
              <OddsTableKenoInput
                kenoType={kenoType}
                kenoStake={kenoStake}
                handleOnChangeKenoTypeInput={handleOnChangeKenoTypeInput}
                handleOnChangeKenoStakeInput={handleOnChangeKenoStakeInput}
              />
            )}
          </>
        )}
        {rows.length > 0 ? (
          <table className="OddsTableContainer__table">
            <thead>
              {rowsHead.map((row, idx) => (
                <OddsTableRow
                  key={idx}
                  columns={row}
                  head={true}
                  className={oddsTableRowClass}
                />
              ))}
            </thead>
            <tbody>
              {rowsBody.map((row, idx) => (
                <OddsTableRow
                  key={idx}
                  columns={row}
                  className={oddsTableRowClass}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <span className="OddsTableContainer__notice">
            Die Ergebnisse der Ziehung werden derzeit noch ausgewertet und
            stehen Ihnen bald zur Verfügung.
          </span>
        )}
        {gameType === OddsTableGameType.Keno && !hideWinningAmounts && (
          <div className="OddsTableContainer__keno-information">
            * Anzahl der Gewinne aller Einsatzhöhen
          </div>
        )}
      </div>
    </div>
  );
};
