import { DateFormat, formatDate, fromTimestamp } from '@/utilities/dateUtils';

export function oddsInputDataFromTimestamp(timestamp: number) {
  const date = fromTimestamp(timestamp);
  return {
    selectedYear: date.year(),
    selectedWeek: {
      key: date.isoWeek(),
      number: date.isoWeek(),
      period: {
        from: formatDate(date.startOf('week'), DateFormat.ISODashed),
        until: formatDate(date.endOf('week'), DateFormat.ISODashed),
      },
    },
    selectedDay: timestamp ? formatDate(timestamp, DateFormat.ISODashed) : null,
    responseData: {
      days: [],
      weeks: [],
      years: [],
    },
  };
}
