export interface VideoLiveTime {
  day: string;
  time: string;
}

export interface VideoConfig {
  baseUrl: string;
  liveMinutesAfter: number;
  liveMinutesPrior: number;
  liveTimes: VideoLiveTime[];
}

export const videoConfig: VideoConfig = {
  baseUrl: 'https://www.youtube.com/watch?v=',
  liveMinutesAfter: 0,
  liveMinutesPrior: 25,
  liveTimes: [
    {
      day: 'Mittwoch',
      time: '18:25',
    },
    {
      day: 'Samstag',
      time: '19:25',
    },
  ],
};
