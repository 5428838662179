import ClientOnly from '@/components/ClientOnly';
import DrawVideo, { DrawVideoVariant } from '@/components/DrawVideo';
import { LogoVariant } from '@/components/LogoVariant';
import { OddsGameAmount } from '@/components/OddsGameAmount';
import { OddsTable, OddsTableGameType } from '@/components/OddsTable';
import { WinningNumbers } from '@/components/WinningNumbers';
import * as eurojackpot from '@/config/game/eurojackpot';
import { OddsVariant } from '@/config/odds/OddsVariant';
import { kenoData, oddsConfigs, plus5Data } from '@/config/odds/oddsConfigs';
import { WinningNumbersData } from '@/config/odds/winningNumbers';
import apiRequest from '@/helpers/apiRequest';
import { oddsInputDataFromTimestamp } from '@/helpers/oddsInputDataFromTimestamp';
import { fromTimestamp } from '@/utilities/dateUtils';
import numberToCurrency from '@/utilities/numberToCurrency';
import dynamic from 'next/dynamic';
import numeral from 'numeral';
import React from 'react';

const OddsDateInput = dynamic(() => import('./OddsDateInput'));
const KenoOddsDateInput = dynamic(() => import('./KenoOddsDateInput'));

const variantIds = {
  [OddsVariant.Eurojackpot]: '1',
  [OddsVariant.GluecksSpirale]: '2',
  [OddsVariant.Keno]: '3',
  [OddsVariant.Lotto6aus49]: '4',
  [OddsVariant.Super6Spiel77]: '4',
  [OddsVariant.Plus5]: '5',
  [OddsVariant.TotoAW]: '6',
  [OddsVariant.TotoEW]: '7',
};

const variantConfigs = {
  [OddsVariant.Eurojackpot]: oddsConfigs.eurojackpot,
  [OddsVariant.GluecksSpirale]: oddsConfigs.gluecksspirale,
  [OddsVariant.Keno]: oddsConfigs.keno,
  [OddsVariant.Lotto6aus49]: oddsConfigs.lotto,
  [OddsVariant.Super6Spiel77]: oddsConfigs.lotto,
  [OddsVariant.Plus5]: oddsConfigs.plus5,
  [OddsVariant.TotoAW]: oddsConfigs.totoaw,
  [OddsVariant.TotoEW]: oddsConfigs.totoew,
};

const variantDrawVideoVariants = {
  [OddsVariant.Eurojackpot]: DrawVideoVariant.Eurojackpot,
  [OddsVariant.Keno]: DrawVideoVariant.Keno,
  [OddsVariant.Lotto6aus49]: DrawVideoVariant.Lotto,
};

const variantGameTypes = {
  [OddsVariant.Eurojackpot]: OddsTableGameType.Eurojackpot,
  [OddsVariant.Keno]: OddsTableGameType.Keno,
  [OddsVariant.Lotto6aus49]: OddsTableGameType.Lotto6aus49,
  [OddsVariant.TotoAW]: OddsTableGameType.Totoaw,
  [OddsVariant.TotoEW]: OddsTableGameType.Totoew,
};

const variantLogos = {
  [OddsVariant.Eurojackpot]: LogoVariant.Eurojackpot,
  [OddsVariant.Keno]: LogoVariant.Keno,
  [OddsVariant.Lotto6aus49]: LogoVariant.Lotto6aus49,
  [OddsVariant.GluecksSpirale]: LogoVariant.GluecksSpirale,
  [OddsVariant.TotoAW]: LogoVariant.TotoAW,
  [OddsVariant.TotoEW]: LogoVariant.TotoEW,
};

export interface IPeriod {
  from: string;
  until: string;
}

export interface IResponseDataItem {
  key: number;
  number: number;
  period: IPeriod;
}

export interface IResponseData {
  days: Array<{ key: string; date: string }>;
  weeks: IResponseDataItem[];
  years: Array<{ key: number; year: number }>;
  selectedDay: string;
  selectedWeek: IResponseDataItem[];
  selectedYear: number;
}

export interface IOddsData {
  responseData?: IResponseData;
  selectedDay?: string;
  selectedWeek?: { key: number; number: number; period: any };
  selectedYear?: number;
}

export interface IOddsProps {
  variant: OddsVariant;
  hideMain?: boolean;
  hideAdditional?: boolean;
  hideWinningNumbers?: boolean;
  hideVideo?: boolean;
  hideOddsTable?: boolean;
  winningNumbersData?: WinningNumbersData;
  hideWinningAmounts?: boolean;
}

interface IOddsState {
  response: any;
  dateInputData: IOddsData;
  kenoType: string;
  kenoStake: string;
}

export default class Odds extends React.Component<IOddsProps, IOddsState> {
  public static defaultProps: Partial<IOddsProps> = {
    hideMain: false,
    hideAdditional: false,
    hideWinningNumbers: false,
    hideVideo: false,
    hideOddsTable: false,
  };

  private static initialKenoType = '10';
  private static initialKenoStake = '10';

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  private get initialState(): IOddsState {
    const winningNumbers = this.props.winningNumbersData;
    const data = winningNumbers
      ? winningNumbers[variantIds[this.props.variant]]
      : null;
    const response = data
      ? this.props.variant === OddsVariant.Keno
        ? data[0] || null
        : data
      : null;

    if (this.props.variant === OddsVariant.Keno) {
      // add plus5 data to response
      response.responsePlus5 =
        this.props.winningNumbersData![variantIds[OddsVariant.Plus5]]![0];
    }

    let dateInputData;

    switch (this.props.variant) {
      case OddsVariant.Keno:
        dateInputData =
          response && oddsInputDataFromTimestamp(response.drawDate);
        break;

      case OddsVariant.TotoAW:
      case OddsVariant.TotoEW:
        dateInputData =
          response && oddsInputDataFromTimestamp(response.untilDate);
        break;

      case OddsVariant.Lotto6aus49:
      case OddsVariant.Super6Spiel77:
        const item = data ? data.sort(this.sortData)[0] : null;
        dateInputData = item && oddsInputDataFromTimestamp(item.drawDate);
        break;

      case OddsVariant.Eurojackpot:
      case OddsVariant.GluecksSpirale:
      default:
        dateInputData =
          response && oddsInputDataFromTimestamp(response.drawDate);
        break;
    }

    return {
      response,
      dateInputData,
      kenoType: Odds.initialKenoType,
      kenoStake: Odds.initialKenoStake,
    };
  }

  private sortData = (a, b): number => {
    return a !== null &&
      b !== null &&
      typeof a.drawDate === 'number' &&
      typeof b.drawDate === 'number'
      ? b.drawDate - a.drawDate
      : 0;
  };

  async fetchDates(suppliedYear?: string) {
    const selectedYear: number | undefined =
      this.state.dateInputData.selectedYear;

    let currentYear;
    const ONE_YEAR_VALUE = 10000000;
    const getTimeStampFromYear = (value: string | number): number => {
      return Date.parse((parseInt(`${value}`) + 1).toString()) - ONE_YEAR_VALUE;
    };
    if (suppliedYear) {
      currentYear = getTimeStampFromYear(suppliedYear);
    }
    if (selectedYear) {
      currentYear = selectedYear && getTimeStampFromYear(selectedYear);
    }
    if (!currentYear) {
      currentYear = new Date().getTime();
    }

    try {
      let response = await apiRequest(
        `${variantConfigs[this.props.variant].datesApiUrl}/${currentYear}`
      );
      if (response && (response.days || response.totoEvents)) {
        if (!response.days && !response.totoEvents) {
          throw 'Response was not valid!';
        }

        const oldState = this.state;
        const newStateData: any = JSON.parse(JSON.stringify(oldState));
        const initialState = this.initialState;
        const initialResponse =
          initialState.response &&
          this.props.variant === OddsVariant.Lotto6aus49
            ? initialState.response[0]
            : initialState.response;
        if (
          initialResponse !== null &&
          typeof initialResponse.drawDate === 'number'
        ) {
          const responseDays = response.days;
          if (this.props.variant === OddsVariant.Keno) {
            const newDays = responseDays;
            response.days = newDays.length > 0 ? newDays : response.days;
            response.weeks = response.weeks.reverse();
            response.years = response.years.reverse();
          } else {
            response.days = responseDays;
          }
        }
        const newDateData = oldState.dateInputData;
        newDateData.responseData = response;
        newDateData.selectedDay = response.days
          ? response.days[0].date
          : response.totoEvents
          ? response.totoEvents.find(
              (event) => new Date(event.period.from).getTime() < Date.now()
            )?.period?.from
          : undefined;
        newDateData.selectedYear = selectedYear
          ? selectedYear
          : response.years[0].year;
        newStateData.dateInputData = newDateData;
        this.setState({ dateInputData: newDateData });

        this.fetchOddsData().then(() => {
          // WORKAROUND: force rerender after fetching odds on slower devices
          setTimeout(() => {
            this.setState({});
          }, 1500);
        });
      }
    } catch (error) {
      console.error('No response from api: ' + error);
    }
  }

  async fetchOddsData() {
    if (this.state?.dateInputData?.selectedDay) {
      const { selectedDay } = this.state.dateInputData;
      const parsedSelectedDay = Date.parse(selectedDay);
      try {
        const response = await apiRequest(
          `${variantConfigs[this.props.variant].apiUrl}/${parsedSelectedDay}`
        );

        if (this.props.variant === OddsVariant.Keno) {
          if (!this.props.hideAdditional) {
            response.responsePlus5 = await apiRequest(
              `${variantConfigs[OddsVariant.Plus5].apiUrl}/${parsedSelectedDay}`
            );
          }
        } else if (
          this.props.variant === OddsVariant.TotoAW ||
          this.props.variant === OddsVariant.TotoEW
        ) {
          // TODO: additional request for winning numbers - has nothing to do with odds...
          const res = await apiRequest(
            `${variantConfigs[this.props.variant].shortInfo}/${
              response.yearEvent
            }`
          );
          if (res?.winningNumbers) {
            response.drawNumbers = res.winningNumbers;
          }
        }
        this.setState({ response });
      } catch (error) {
        console.error('Odds - fetchOddsData failed', error);
      }
    } else {
      console.warn('No selectedDay');
    }
  }

  async componentDidMount() {
    this.fetchDates(undefined).catch((error) =>
      console.error('No response from api: ' + error)
    );
  }

  handleOnChangeKenoTypeInput = ($event) =>
    this.setState({
      kenoType: $event.target.value,
    });

  handleOnChangeKenoStakeInput = ($event) =>
    this.setState({
      kenoStake: $event.target.value,
    });

  handleOnChangeDate = (name, value) => {
    if (name === 'selectedYear') {
      this.setState(
        ({ dateInputData }: IOddsState) => {
          return { dateInputData: { ...dateInputData, [name]: value } };
        },
        () => this.fetchDates(value)
      );
    } else if (name === 'selectedDay') {
      this.setState(({ dateInputData }: IOddsState) => {
        return { dateInputData: { ...dateInputData, [name]: value } };
      }, this.fetchOddsData);
    } else if (name === 'fullDate') {
      this.setState(({ dateInputData }: IOddsState) => {
        return {
          dateInputData: {
            ...dateInputData,
            selectedYear: fromTimestamp(value).year(),
            selectedDay: fromTimestamp(value).format('YYYY-MM-DD'),
          },
        };
      }, this.fetchOddsData);
    }
  };

  private formatWinningclass(kenoType: number, winningClass: number) {
    const customFormat = '00';
    return `${numeral(kenoType).format(customFormat)}${numeral(
      winningClass
    ).format(customFormat)}`;
  }

  private getRows(
    oddsCollection,
    currency,
    useKenoOrPlus5Data?: 'keno' | 'plus5'
  ): Array<Array<string | number>> {
    if (useKenoOrPlus5Data === 'keno') {
      if (oddsCollection) {
        const kenoType: string = this.state.kenoType || Odds.initialKenoType;
        const kenoStake: string = this.state.kenoStake || Odds.initialKenoStake;

        const oddsData = kenoData[kenoType];
        const rows = oddsData.map((oddsRow) => {
          const oddsCollectionItem = oddsCollection.find(
            (item) =>
              item.winningClass ===
              this.formatWinningclass(+kenoType, oddsRow.winningClass)
          );

          const row = [
            oddsRow.winningClass.toString(),
            oddsRow.winningClassName,
            `${
              oddsCollectionItem &&
              typeof oddsCollectionItem.numberOfWinners !== 'undefined'
                ? `${numeral(oddsCollectionItem.numberOfWinners).format(
                    '0,0'
                  )} ×`
                : 'nicht verfügbar'
            } `,
            `1:${numeral(oddsRow.odds).format('0,0')}`,
            oddsRow.odds
              ? numberToCurrency(
                  oddsRow.winningSum * +kenoStake,
                  currency,
                  true
                )
              : 'unbesetzt',
          ];
          return row;
        });

        rows.unshift(variantConfigs[this.props.variant].headColumns);
        return rows.map((row) => [
          `${row[0]} /<br/>${row[1]}`,
          ...row,
          `${row[row.length - 2]} /<br/>${row[row.length - 1]}`,
        ]);
      }
      return [[]];
    } else if (useKenoOrPlus5Data === 'plus5') {
      if (oddsCollection) {
        const rows = plus5Data.map((item) => {
          const oddsRow = oddsCollection.find(
            (odd) => +odd.winningClass === item.winningClass
          );

          const row = [
            item ? item.winningClass : '',
            (item && item.winningClassName) || '-',
            oddsRow
              ? `${numeral(oddsRow.numberOfWinners).format('0,0')} ×`
              : 'nicht verfügbar',
            (item && `1:${numeral(item.odds).format('0,0')}`) || '-',
            numberToCurrency(item.winningSum, currency, true),
          ];
          return row;
        });

        rows.unshift(variantConfigs[OddsVariant.Plus5].headColumns);

        return rows.map((row) => [
          `${row[0]} /<br/>${row[1]}`,
          ...row,
          `${row[row.length - 2]} /<br/>${row[row.length - 1]}`,
        ]);
      }
      return [[]];
    } else {
      if (oddsCollection) {
        const sortedOddsCollection =
          this.props.variant === OddsVariant.Eurojackpot
            ? oddsCollection.sort((a, b) => +a.winningClass - +b.winningClass)
            : oddsCollection;

        const rows = sortedOddsCollection.map((oddsRow) => {
          const row = [
            this.props.variant === OddsVariant.Keno
              ? oddsRow.winningClassDescription.winningClassNumber
              : oddsRow.winningClass.toString(),
            this.props.variant === OddsVariant.Eurojackpot
              ? oddsRow.winningClassDescription.winningClassName.replace(
                  '+',
                  '<br/>+'
                )
              : oddsRow.winningClassDescription
              ? oddsRow.winningClassDescription.winningClassName
              : '-',

            `${numeral(oddsRow.numberOfWinners).format('0,0')} ×`,
          ];

          if (oddsRow.chance) {
            row.push(oddsRow.chance);
          }
          row.push(
            oddsRow.odds
              ? numberToCurrency(oddsRow.odds, currency, true)
              : 'unbesetzt'
          );

          return row;
        });

        rows.unshift(variantConfigs[this.props.variant].headColumns);

        return rows.map((row) => [
          ...row,
          `${row[row.length - 2]} /<br/>${row[row.length - 1]}`,
        ]);
      }
      return [[]];
    }
  }

  private get shouldShowDateInput(): boolean {
    return (
      ((this.props.variant === OddsVariant.Eurojackpot ||
        this.props.variant === OddsVariant.GluecksSpirale) &&
        this.props.hideMain === false) ||
      (this.props.variant === OddsVariant.Lotto6aus49 &&
        !!this.state.dateInputData.responseData) ||
      (this.props.variant === OddsVariant.Keno &&
        (this.props.hideWinningNumbers === false ||
          this.props.hideVideo === false)) ||
      (this.props.variant === OddsVariant.TotoAW &&
        this.props.hideWinningNumbers === false) ||
      (this.props.variant === OddsVariant.TotoEW &&
        this.props.hideWinningNumbers === false)
    );
  }

  private drawDateInput(): JSX.Element {
    switch (this.props.variant) {
      case OddsVariant.GluecksSpirale:
        return (
          <OddsDateInput
            handler={this.handleOnChangeDate}
            data={this.state.dateInputData}
          />
        );
      case OddsVariant.Eurojackpot:
        return (
          <OddsDateInput
            handler={this.handleOnChangeDate}
            data={this.state.dateInputData}
            dateFormat={eurojackpot.getOddsDatePickerFormat()}
          />
        );
      case OddsVariant.Keno:
        return (
          <KenoOddsDateInput
            key={this.state.dateInputData.selectedDay}
            handler={this.handleOnChangeDate}
            data={this.state.dateInputData}
          />
        );

      case OddsVariant.Lotto6aus49:
      case OddsVariant.Super6Spiel77:
      default:
        return (
          <OddsDateInput
            handler={this.handleOnChangeDate}
            data={this.state.dateInputData}
          />
        );
    }
  }

  private drawWinningNumbers(data): JSX.Element {
    return this.props.variant === OddsVariant.TotoAW ||
      this.props.variant === OddsVariant.TotoEW ? (
      <WinningNumbers
        variant={this.props.variant}
        winningNumbers={data}
        showDate={true}
        showHeader={false}
        showFooter={true}
        showLogoHeader={true}
        showLargeHeader={true}
        hideMain={this.props.hideMain === true}
        hideAdditional={this.props.hideAdditional === true}
        showWinningClass={true}
      >
        <OddsGameAmount
          gameAmount={data.gameAmount}
          currency={data.currency.name}
        />
      </WinningNumbers>
    ) : this.props.variant === OddsVariant.GluecksSpirale ? (
      <WinningNumbers
        variant={this.props.variant}
        winningNumbers={data}
        showDate={true}
        showHeader={false}
        showFooter={true}
        showLogoHeader={true}
        showLargeHeader={true}
        hideMain={this.props.hideMain === true}
        hideAdditional={this.props.hideAdditional === true}
        showWinningClass={true}
      >
        <OddsGameAmount
          gameAmount={data.gameAmount}
          currency={data.currency.name}
        />
      </WinningNumbers>
    ) : (
      <WinningNumbers
        variant={this.props.variant}
        winningNumbers={data}
        showDate={true}
        showHeader={false}
        showFooter={true}
        hideMain={this.props.hideMain === true}
        hideAdditional={this.props.hideAdditional === true}
      />
    );
  }

  private drawWinningNumbersAndVideo(draw): JSX.Element {
    return (
      <>
        {this.props.hideWinningNumbers === false &&
          this.drawWinningNumbers(draw)}
        {this.props.hideVideo === false &&
          draw.drawDate &&
          [
            OddsVariant.Keno,
            OddsVariant.Eurojackpot,
            OddsVariant.Lotto6aus49,
          ].includes(this.props.variant) &&
          this.props.hideMain === false && (
            <DrawVideo
              date={draw.drawDate}
              variant={variantDrawVideoVariants[this.props.variant]}
            />
          )}
      </>
    );
  }

  private drawOddsTable(
    draw,
    forceAdditionalHidden: boolean = false,
    label: string = '',
    forceOnlyAdditional: boolean = false
  ): JSX.Element {
    return (
      <>
        {this.props.hideMain === false &&
          this.props.hideOddsTable === false &&
          this.props.variant !== OddsVariant.GluecksSpirale &&
          !forceOnlyAdditional && (
            <OddsTable
              gameType={variantGameTypes[this.props.variant]}
              gameAmount={draw.gameAmount}
              currency={draw.currency ? draw.currency.name : '€'}
              logo={variantLogos[this.props.variant]}
              rows={this.getRows(
                draw.oddsCollection ? draw.oddsCollection : [],
                draw.currency ? draw.currency.name : '€',
                this.props.variant === OddsVariant.Keno ? 'keno' : undefined
              )}
              handleOnChangeKenoStakeInput={this.handleOnChangeKenoStakeInput}
              handleOnChangeKenoTypeInput={this.handleOnChangeKenoTypeInput}
              kenoType={this.state.kenoType}
              kenoStake={this.state.kenoStake}
              label={label}
              hideWinningAmounts={this.props.hideWinningAmounts}
            />
          )}

        {this.props.hideAdditional === false &&
          this.props.hideOddsTable === false &&
          (!forceAdditionalHidden || forceOnlyAdditional) && (
            <>
              {this.props.variant === OddsVariant.Lotto6aus49 && (
                <>
                  {draw.game77 && (
                    <OddsTable
                      gameType={OddsTableGameType.Spiel77}
                      gameAmount={draw.game77.gameAmount}
                      currency={draw.game77.currency.name}
                      logo={LogoVariant.Spiel77OnBackground}
                      rows={this.getRows(
                        draw.game77.oddsCollection,
                        draw.currency.name,
                        undefined
                      )}
                    />
                  )}

                  {draw.super6 && (
                    <OddsTable
                      gameType={OddsTableGameType.Super6}
                      gameAmount={draw.super6.gameAmount}
                      currency={draw.super6.currency.name}
                      logo={LogoVariant.Super6OnBackground}
                      rows={this.getRows(
                        draw.super6.oddsCollection,
                        draw.currency.name,
                        undefined
                      )}
                    />
                  )}
                </>
              )}
              {this.props.variant === OddsVariant.Keno && (
                <OddsTable
                  rows={this.getRows(
                    (draw.responsePlus5 && draw.responsePlus5.oddsCollection) ||
                      [],
                    '€',
                    'plus5'
                  )}
                  currency={'€'}
                  logo={LogoVariant.Plus5OnBackground}
                  gameType={OddsTableGameType.Plus5}
                  gameAmount={
                    (draw.responsePlus5 && draw.responsePlus5.gameAmount) ||
                    draw.p5GameAmount
                  }
                />
              )}
            </>
          )}
      </>
    );
  }

  areDuplicateOdds = (drawA: any, drawB: any) => {
    if (drawA?.oddsCollection && drawB?.oddsCollection) {
      for (let idx = 0; idx < drawA.oddsCollection.length; idx++) {
        if (drawA.oddsCollection?.[idx]) {
          drawA.oddsCollection[idx].id = undefined;
        }
        if (drawB.oddsCollection?.[idx]) {
          drawB.oddsCollection[idx].id = undefined;
        }
      }

      if (drawA.oddsCollection && drawB.oddsCollection) {
        return (
          JSON.stringify(drawA.oddsCollection).replace('  ', ' ') ===
          JSON.stringify(drawB.oddsCollection).replace('  ', ' ')
        );
      }
    }
    return false;
  };

  render() {
    const severalDraws: boolean =
      this.state.response instanceof Array &&
      this.state.response.length === 2 &&
      this.state.response[0] !== null &&
      !Number.isNaN(parseInt(this.state.response[0].drawDate)) &&
      !(parseInt(this.state.response[0].drawDate) > 975538800000);
    const draw = this.state.response
      ? this.state.response instanceof Array
        ? this.state.response[0]
        : this.state.response
      : null;
    return (
      <ClientOnly>
        {severalDraws ? (
          <>
            {this.shouldShowDateInput && this.drawDateInput()}
            <div className="DrawContainer">
              <h3 className="headline">Ziehung A</h3>
              {this.drawWinningNumbersAndVideo(this.state.response[0])}
              {this.drawOddsTable(this.state.response[0], true, 'Ziehung A')}
              <h3 className="headline">Ziehung B</h3>
              {this.drawWinningNumbersAndVideo(this.state.response[1])}
              {!this.areDuplicateOdds(
                this.state.response[0],
                this.state.response[1]
              ) &&
                this.drawOddsTable(this.state.response[1], true, 'Ziehung B')}
              {this.drawOddsTable(this.state.response[0], false, '', true)}
            </div>
          </>
        ) : (
          draw && (
            <>
              {this.shouldShowDateInput && this.drawDateInput()}
              <div className="DrawContainer">
                {this.drawWinningNumbersAndVideo(draw)}
                {this.drawOddsTable(draw)}
              </div>
            </>
          )
        )}
      </ClientOnly>
    );
  }
}
